<div class="actions">
  <app-prev-chapter [hide]="hidePrevButton || hideActions"
                    [tooltip]="hidePrevButton ? null : 'rozdział ' + (chapter - 1)"
                    tooltipPosition="left"
                    (chapterChange$)="chapterChange$.emit($event)">
  </app-prev-chapter>

  <div>
    <div class="actions-center">
      <app-icon-button *onlyDesktop label="lewy panel" icon="view_sidebar" tooltip="lewy panel" rotateIcon="true"
                       [active]="showLeftPanel" [disabled]="hidePanels || hideActions" altHover="true"
                       (click$)="showLeftPanel = !showLeftPanel"
      ></app-icon-button>

      <app-icon-button *onlyDesktopTabletLandscape label="wersety w nowej linii" icon="toc" tooltip="wersety w nowej linii"
                       rotateIcon="true" [active]="versesInNewLine" [disabled]="hideActions" altHover="true"
                       (click$)="versesInNewLine = !versesInNewLine"
      ></app-icon-button>

      <app-icon-button label="ulubiony rozdział" icon="star" tooltip="ulubiony rozdział" [active]="isFavouriteChapter"
                       [disabled]="hideActions" altHover="true" (click$)="favouriteChapterChange()"
      ></app-icon-button>

      <app-icon-button *onlyDesktop label="pełny ekran" icon="screen" tooltip="pełny ekran" [active]="fullScreen"
                       [disabled]="hideActions" altHover="true" (click$)="fullScreen = !fullScreen"
      ></app-icon-button>

      <app-icon-button label="wybór ksiąg biblijnych" icon="import_contacts" tooltip="księgi biblijne"
                       [disabled]="hideActions" altHover="true" (click$)="openBibleBookDialog$.emit()"
      ></app-icon-button>

      <app-icon-button *onlyPortraitLandscapeTablet label="narzędzia biblijne" icon="low_priority" tooltip="narzędzia"
                       [disabled]="hideActions" altHover="true" (click$)="openToolsPanel$.emit()"
      ></app-icon-button>

      <app-icon-button *onlyDesktopTabletLandscape label="wybór przekładów biblijnych" icon="settings" tooltip="wybór przekładów"
                       [disabled]="hideActions" altHover="true" (click$)="openSelectTranslationsDialog$.emit()"
      ></app-icon-button>

      <app-icon-button *onlyDesktopTabletLandscape label="kopiuj wersety" icon="content_copy" tooltip="kopiuj wersety"
                       [disabled]="hideActions" altHover="true" (click$)="copyVerses$.emit()"
      ></app-icon-button>

      <app-icon-button *onlyDesktopTablet label="wyszukiwarka" icon="search" tooltip="wyszukiwarka"
                       [disabled]="hideActions" altHover="true" link="../wyszukaj"
      ></app-icon-button>

      <app-icon-button *onlyTablet label="zmiana rozmiary czcionki" icon="font-increase" tooltip="zmiana rozmiary czcionki"
                       [disabled]="hideActions" altHover="true" (click$)="changeFontSize()"
      ></app-icon-button>

      <app-icon-button *onlyDesktop label="prawy panel" icon="view_sidebar" tooltip="prawy panel" [active]="showRightPanel"
                       [disabled]="hidePanels || hideActions" altHover="true" (click$)="showRightPanel = !showRightPanel"
      ></app-icon-button>

      <app-icon-button *onlyLandscape label="zmiana rozmiary czcionki" icon="font-increase" tooltip="zmiana rozmiary czcionki"
                       altHover="true" (click$)="changeFontSize()"
      ></app-icon-button>
    </div>
  </div>

  <app-next-chapter [hide]="hideNextButton || hideActions"
                    [tooltip]="hideNextButton ? null : 'rozdział ' + (chapter + 1)"
                    tooltipPosition="right"
                    (chapterChange$)="chapterChange$.emit($event)">
  </app-next-chapter>
</div>
