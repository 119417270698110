<div class="icon-wrapper">
  <mat-icon svgIcon="icons:switch_left"
            [matTooltip]="hidden ? '' : tooltip"
            matTooltipPosition="left"
            [attr.aria-label]="ariaLabel"
            [class.collapse]="state === BibleSideExpandState.COLLAPSE"
            [tabIndex]="hidden ? -1 : 0"
            (click)="arrowClick()">
  </mat-icon>
</div>
