import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';

import { ButtonComponent } from '../../../../../components/button/button.component';
import { CopyVersesDialogComponent } from './copy-verses-dialog.component';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [CopyVersesDialogComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    IconButtonComponent,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MediaDirectivesModule,
    SharedPipesModule,
  ],
  exports: [CopyVersesDialogComponent],
})
export class CopyVersesDialogModule {
}
