<div class="left-panel">
  @if (!sideNavOpen && over1700px) {
    <app-bible-collapse-button
      blockTimeout="400" [hidden]="hidden" [reverse]="true" (expandChange$)="expandChange($event)">
    </app-bible-collapse-button>
  }

  <button class="item label favourite-label" [matTooltip]="collapse && !hidden ? verseListName : ''" matTooltipPosition="right"
    (click)="openVersesLists()">
    <span class="text">
      <span class="hint">Lista:</span>
      {{ verseListName }}
    </span>
    <mat-icon svgIcon="icons:star"></mat-icon>
  </button>

  <div class="container scroll-container list" cdkDropList (cdkDropListDropped)="drop($event)">
    @if (bookGroup) {
      @for (item of favouriteChapters; track $index) {
        <div cdkDrag (click)="openBottomSheet(item)" [cdkDragDisabled]="hidden"
          class="item button box" [class.disabled]="!item || hidden" [tabIndex]="hidden ? -1 : 0" matTooltipPosition="right"
              [matTooltip]="collapse && !hidden ? ((item.book | bookNameInBooks : books) + ' ' + (item.chapter || 1)
                + (item.verse ? (',' + item.verse) : '') + ' w ' + (item.bible | uppercase)) : ''">
          <div class="placeholder" *cdkDragPlaceholder></div>
          <span class="ellipsis">
            {{ collapse ? ((item.book | abbreviation) + '.') : ((item.book | bookNameInBooks : books)) }}
            {{ ' ' + (item.chapter || 1) }}{{ item.verse ? (',' + item.verse) : ''}}
          </span>
          @if (!collapse) {
            <span>{{ item.bible | uppercase }}</span>
          }
        </div>
      }
    }

    @if (!bookGroup) {
      <div class="loader">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    }
  </div>

  @if (bookGroup && favouriteChapters.length === 0) {
    <div class="no-results">brak</div>
  }

  <div class="item label last-search-label scroll-container"
    [matTooltip]="collapse && !hidden ? 'Ostatnio wyszukiwane' : ''" matTooltipPosition="right">
    <span class="text">Ostatnio wyszukiwane</span>

    <mat-icon svgIcon="icons:history"></mat-icon>
  </div>

  <div class="container">
    @if (bookGroup) {
      @for (item of lastChapters; track $index) {
        <a
          [disabled]="hidden" class="item button"
          [tabIndex]="hidden ? -1 : 0"
          [class.disabled]="hidden"
         [matTooltip]="collapse && !hidden ? ((item.book | bookNameInBooks : books) + ' ' + (item.chapter || 1)
              + (item.verse ? (',' + item.verse) : '') + ' w ' + (item.bible | uppercase)) : ''"
          matTooltipPosition="right"
          (click)="lastSearchChapter(item, $event)"
          (auxclick)="lastSearchChapter(item, $event)"
          (mousedown)="preventScrollBarButton($event)"
          >
          <span class="ellipsis">
            {{ collapse ? ((item.book | abbreviation) + '.') : ((item.book | bookNameInBooks : books)) }}
            {{ ' ' + (item.chapter || 1) }}
          </span>
          @if (!collapse) {
            <span>{{ item.bible | uppercase }}</span>
          }
        </a>
      }
    }

    @if (!bookGroup) {
      <div class="loader">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    }
  </div>
</div>
