import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BibleFilterActionsComponent } from './actions/bible-filter-actions.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';

import { BibleFilterComponent } from './bible-filter.component';
import { IconButtonComponent } from '../../../../../components/icon-button/icon-button.component';
import { LoaderComponent } from '../../../../../components/loader/loader.component';
import { MediaDirectivesModule } from '../../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [
    BibleFilterComponent,
    BibleFilterActionsComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    IconButtonComponent,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MediaDirectivesModule,
    LoaderComponent,
    ReactiveFormsModule,
    RouterModule,
    SharedPipesModule,
  ],
  exports: [BibleFilterComponent, BibleFilterActionsComponent],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'full-page' }
    }
  ],
})
export class BibleFilterModule {}
