import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';

import { ButtonComponent } from '../../../../../../components/button/button.component';
import { LeftPanelCopyChapterDialogComponent } from './left-panel-copy-chapter-dialog.component';

@NgModule({
  declarations: [LeftPanelCopyChapterDialogComponent],
  imports: [
    ButtonComponent,
    CommonModule,
    MatDialogModule,
  ],
  exports: [LeftPanelCopyChapterDialogComponent],
})
export class LeftPanelCopyChapterDialogModule {}
