import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core';

import { StateService } from '../../../../../../services/state/state.service';

@Component({
  selector: 'app-bible-filter-actions',
  templateUrl: './bible-filter-actions.component.html',
  styleUrls: ['./bible-filter-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleFilterActionsComponent implements OnInit {
  @Output() protected readonly openSelectTranslationsDialog$ = new EventEmitter<void>();
  @Output() protected readonly copyVerses$ = new EventEmitter<void>();
  @Output() protected readonly changeFontSize$ = new EventEmitter<number>();
  @Output() protected readonly versesInNewLine$ = new EventEmitter<boolean>();

  @HostBinding('class.hide-verse-in-new-line') @Input({ required: true }) readonly hideVerseInNewLine = false;

  private stateService = inject(StateService);

  protected get bibleFontSize(): number {
    return this.stateService.bibleFontSize;
  }

  protected get versesInNewLine(): boolean { return this.stateService.bibleVersesInNewLine; }
  protected set versesInNewLine(value: boolean) {
    this.versesInNewLine$.emit(value);
    this.stateService.setBibleVersesInNewLine(value);
  }

  ngOnInit(): void {
    this.versesInNewLine$.emit(this.versesInNewLine);
  }

  protected changeFontSize(): void {
    if (this.bibleFontSize === 4) {
      this.stateService.setBibleFontSize(0);
    } else {
      this.stateService.setBibleFontSize(this.bibleFontSize + 1);
    }
    this.changeFontSize$.emit(this.stateService.bibleFontSize);
  }
}
