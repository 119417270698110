/* src/app/pages/common/bible/left-panel/bible-left-panel.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host .left-panel {
  width: 86px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1rem;
  border: 2px solid var(--color-disable);
  border-bottom: 1px solid var(--color-disable);
  background-color: var(--color-background-dark);
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1) opacity, 400ms cubic-bezier(0.25, 0.8, 0.25, 1) width;
  opacity: 1;
  z-index: 2;
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
:host .left-panel app-bible-collapse-button {
  position: absolute;
  top: -32px;
  left: 0;
}
@media only screen and (min-width: 1024px) {
  :host .left-panel {
    width: 300px;
  }
}
:host .left-panel .container {
  display: flex;
  flex-direction: column;
}
:host .left-panel .container.scroll-container {
  max-height: 205px;
  overflow-y: auto;
}
:host .left-panel .container.scroll-container::-webkit-scrollbar {
  width: 8px;
}
:host .left-panel .container.scroll-container .button:last-of-type {
  border-bottom: none;
}
:host .left-panel .container .loader {
  min-height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .left-panel .container .item {
  background-color: transparent;
  font-size: 1rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host .left-panel .container .button {
  border-bottom: 1px solid var(--color-disable);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.6rem 1.5rem;
  min-height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: background 400ms cubic-bezier(0.79, 0, 0.22, 1);
  cursor: pointer;
  text-decoration: none;
  color: var(--color-text);
}
:host .left-panel .container .button.box:last-child {
  border: none;
}
:host .left-panel .container .button:hover,
:host .left-panel .container .button:focus {
  background-color: var(--color-brand-hover);
  color: var(--color-text-background-dark);
  cursor: pointer;
}
:host .left-panel .container .button.disabled {
  background-color: rgba(var(--color-background-rgb), 0.4);
  cursor: default;
}
:host .left-panel .container .button .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host .left-panel .container.list.cdk-drop-list-dragging .button:hover {
  background-color: var(--color-background-dark);
  color: var(--color-text);
  cursor: move;
}
:host .left-panel .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-disable);
  font-size: 1.2rem;
  background-color: var(--color-background);
  color: var(--color-brand);
  padding: 0.7rem 1rem;
  transition: 400ms cubic-bezier(0.25, 0.8, 0.25, 1) padding;
}
:host .left-panel .label.favourite-label {
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .left-panel .label.favourite-label:hover,
:host .left-panel .label.favourite-label:focus {
  background-color: var(--color-brand-hover);
}
:host .left-panel .label.last-search-label {
  border-top: 2px solid var(--color-disable);
}
:host .left-panel .label .text {
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1) width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-brand);
}
:host .left-panel .label .text .hint {
  color: var(--color-text);
  font-size: 0.77rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .left-panel .label .text .hint {
    font-size: 0.7rem;
  }
}
:host .left-panel .label .mat-icon {
  min-width: 24px;
  --mat-icon-color: $color-brand;
}
:host .left-panel .no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.5rem;
  min-height: 41px;
  box-sizing: border-box;
  color: var(--color-disable);
}
:host-context(.sticky) .left-panel {
  position: fixed;
  top: 70px;
  margin-top: 1.5rem;
}
:host-context(.over-1700) .left-panel {
  left: 2rem;
}
:host-context(.over-1700) .left-panel .button {
  padding: 0.7rem;
}
:host-context(.sticky.over-1700) .left-panel {
  margin-top: 4rem;
}
:host-context(.hide-left-panel) .left-panel {
  z-index: -1;
  opacity: 0;
  cursor: default;
}
:host-context(.hide-left-panel) .left-panel .button {
  cursor: default;
}
:host-context(.animating) .left-panel {
  display: none;
}
:host-context(.sticky-animation) .left-panel {
  transition:
    400ms cubic-bezier(0.79, 0, 0.22, 1) opacity,
    400ms cubic-bezier(0.25, 0.8, 0.25, 1) width,
    400ms cubic-bezier(0.25, 0.8, 0.25, 1) left;
}
:host-context(.collapse) .left-panel {
  width: 86px;
}
:host-context(.collapse) .left-panel .label {
  padding-right: calc(1rem + 15px);
}
:host-context(.collapse) .left-panel .label .text {
  width: 0;
  height: 0;
}
:host-context(.collapse) .left-panel .container .button {
  padding: 0.6rem 0.2rem;
  justify-content: center;
}
:host-context(.sticky.collapse.sidenav-open) .left-panel {
  left: calc(320px + 2rem);
}
:host-context(.sticky.collapse.sidenav-open:not(.over-1700)) .left-panel {
  left: calc(320px + 1rem);
}
.cdk-drag-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.6rem;
}
.placeholder {
  min-height: 35px;
}
/*# sourceMappingURL=bible-left-panel.component.css.map */
