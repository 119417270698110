import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BibleActionsComponent } from './bible-actions/bible-actions.component';
import { BibleBookInfoDialogModule } from './bible-book-info-dialog/bible-book-info-dialog.module';
import { BibleCollapseButtonComponent } from './filter/bible-right-panel/bible-collapse-button/bible-collapse-button.component';
import { BibleComponent } from './bible.component';
import { BibleContentBottomSheetModule } from './content/bottom-sheet/bible-content-bottom-sheet.module';
import { BibleContentComponent } from './content/bible-content.component';
import { BibleFavouritesMobileDialogModule } from './side-tools/bible-favourites-mobile-dialog.module';
import { BibleFilterModule } from './filter/bible-filter/bible-filter.module';
import { BibleLeftPanelComponent } from './left-panel/bible-left-panel.component';
import { BibleRightPanelComponent } from './filter/bible-right-panel/bible-right-panel.component';
import { BibleSearchQueryComponent } from './bible-search-query/bible-search-query.component';
import { ChapterButtonModule } from './chapter-button/chapter-button.module';
import { CompareVerseDialogModule } from './compare-verse-dialog/compare-verse-dialog.module';
import { CopyVersesDialogModule } from './content/copy-verses-dialog/copy-verses-dialog.module';
import { IconButtonComponent } from '../../../components/icon-button/icon-button.component';
import { LeftPanelBottomSheetComponent } from './left-panel/bottom-sheet/left-panel-bottom-sheet.component';
import { LeftPanelCopyChapterDialogModule } from './left-panel/bottom-sheet/copy-chapter-dialog/left-panel-copy-chapter-dialog.module';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { MediaDirectivesModule } from '../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';
import { VersesListDialogService } from '../../../services/verses-list-dialog.service';

@NgModule({
  declarations: [
    BibleActionsComponent,
    BibleCollapseButtonComponent,
    BibleComponent,
    BibleContentComponent,
    BibleLeftPanelComponent,
    BibleRightPanelComponent,
    LeftPanelBottomSheetComponent,
  ],
  imports: [
    ...BibleModule.angular,
    ...BibleModule.app,
    ...BibleModule.material,
  ],
  exports: [BibleComponent],
  providers: [VersesListDialogService],
})
export class BibleModule {
  static angular = [
    ClipboardModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ];

  static app = [
    BibleBookInfoDialogModule,
    BibleContentBottomSheetModule,
    BibleFavouritesMobileDialogModule,
    BibleFilterModule,
    BibleSearchQueryComponent,
    ChapterButtonModule,
    CompareVerseDialogModule,
    CopyVersesDialogModule,
    IconButtonComponent,
    MediaDirectivesModule,
    LeftPanelCopyChapterDialogModule,
    LoaderComponent,
    SharedPipesModule,
    SnackbarComponent,
  ];

  static material = [
    MatBottomSheetModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
  ];
}
