import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener, inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { BibleGroup, BibleItem } from '../bible.model';
import { BibleSideExpandState } from '../filter/bible-right-panel/bible-collapse-button/bible-collapse-button.component';
import { FavouriteChapter } from '../../../../services/left-panel/favourite-chapter.model';
import {
  LeftPanelBottomSheetComponent,
  LeftPanelBottomSheetResult,
  LeftPanelBottomSheetResultType
} from './bottom-sheet/left-panel-bottom-sheet.component';
import { LeftPanelService } from '../../../../services/left-panel/left-panel.service';
import { SubComponent } from '../../../../components/utils/sub/sub.component';
import { VersesListDialogComponent } from './verses-list-dialog/verses-list-dialog.component';
import { VersesListDialogService } from '../../../../services/verses-list-dialog.service';

export interface SelectChapterEvent {
  selectChapter: FavouriteChapter,
  openInBlankPage?: boolean;
}

@Component({
  selector: 'app-bible-left-panel',
  templateUrl: './bible-left-panel.component.html',
  styleUrls: ['./bible-left-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleLeftPanelComponent extends SubComponent implements OnInit {
  @Output() protected readonly selectChapter$ = new EventEmitter<SelectChapterEvent | FavouriteChapter>();
  @Output() protected readonly selectVerse$ = new EventEmitter<SelectChapterEvent | FavouriteChapter>();
  @Output() protected readonly copyChapter$ = new EventEmitter<FavouriteChapter>();
  @Output() protected readonly copyChapterWithNumbers$ = new EventEmitter<FavouriteChapter>();
  @Output() protected readonly compareVerse$ = new EventEmitter<FavouriteChapter>();

  @Input() bibleGroup: BibleGroup[];
  @Input({ required: true }) bookGroup: BibleGroup[];
  @Input({ required: true }) favouriteChapters: FavouriteChapter[];
  @Input({ required: true }) books: BibleItem[];
  @Input({ required: true }) lastChapters: FavouriteChapter[];

  @Input({ required: true }) @HostBinding('class.over-1700') over1700px: boolean;
  @Input({ required: true }) @HostBinding('class.hide-left-panel')hidden: boolean;
  @Input({ required: true }) @HostBinding('class.collapse') collapse: boolean;
  @Input({ required: true }) @HostBinding('class.sidenav-open') sideNavOpen: boolean;

  @HostBinding('class.animating') firstAnimationDuration = false;
  @HostBinding('class.sticky') sticky = false;
  @HostBinding('class.sticky-animation') stickyAnimation = false;

  private bottomSheet = inject(MatBottomSheet);
  private cdr = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);
  private leftPanelService = inject(LeftPanelService);
  private versesListDialogService = inject(VersesListDialogService);

  protected verseListName = VersesListDialogComponent.defaultListName;

  ngOnInit(): void {
    if (this.hidden) {
      this.firstAnimationDuration = true;
      setTimeout(() => this.firstAnimationDuration = false, 400); // $animation-duration-hover
    }
    this.observeCurrentVersesList();
  }

  protected expandChange(expand: BibleSideExpandState): void {
    this.collapse = !!expand;
  }

  protected drop(event: CdkDragDrop<FavouriteChapter[]>): void {
    moveItemInArray(this.favouriteChapters, event.previousIndex, event.currentIndex);
    this.leftPanelService.setFavouriteChapters(this.favouriteChapters);
  }

  protected openBottomSheet(item: FavouriteChapter): void {
    if (this.hidden) {
      return;
    }
    this.bottomSheet.open(LeftPanelBottomSheetComponent, { data: item }).afterDismissed().subscribe((result: LeftPanelBottomSheetResult) => {
      switch(result?.type) {
        case LeftPanelBottomSheetResultType.OPEN_CHAPTER: this.openChapter(result.data); break;
        case LeftPanelBottomSheetResultType.OPEN_VERSE: this.openVerse(result.data); break;
        case LeftPanelBottomSheetResultType.COPY_CHAPTER_WITH_NUMBERS: this.copyChapterWithNumbers(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.COPY_CHAPTERS: this.copyChapter(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.REMOVE_CHAPTER: this.removeChapter(result.data as FavouriteChapter); break;
        case LeftPanelBottomSheetResultType.COMPARE_VERSE: this.compareVerse(result.data as FavouriteChapter); break;
      }
    });
  }

  protected openVersesLists(): void {
    this.dialog.open(VersesListDialogComponent, { panelClass: 'reading', minWidth: '500px', maxWidth: '80vw' });
  }

  protected lastSearchChapter(item: FavouriteChapter, $event: MouseEvent): void {
    if ($event.button === 0) {
      this.selectChapter$.emit(item);
    } else if ($event.button === 1) {
      this.selectChapter$.emit({ selectChapter: item, openInBlankPage: true });
    }
  }

  protected preventScrollBarButton($event: MouseEvent): void {
    if ($event.button === 1) {
      $event.preventDefault();
    }
  }

  protected observeCurrentVersesList(): void {
    this.subscription.add(
      this.versesListDialogService.currentList$.subscribe(listName => {
        this.verseListName = listName;
        this.cdr.markForCheck();
      })
    );
  }

  @HostListener('window:scroll', ['$event'])
  private trackScrollPosition(): void {
    this.sticky = window.scrollY > (this.over1700px ? 70 : 110); // $top-bar-height-desktop

    if (this.sticky) {
      setTimeout(() => this.stickyAnimation = true, 1);
    } else {
      this.stickyAnimation = false;
    }
  }

  private openChapter(item: SelectChapterEvent | FavouriteChapter): void {
    this.selectChapter$.emit(item);
  }

  private openVerse(item: SelectChapterEvent | FavouriteChapter): void {
    this.selectVerse$.emit(item);
  }

  private copyChapterWithNumbers(item: FavouriteChapter): void {
    this.copyChapterWithNumbers$.emit(item);
  }

  private copyChapter(item: FavouriteChapter): void {
    this.copyChapter$.emit(item);
  }

  private removeChapter(item: FavouriteChapter): void {
    this.leftPanelService.removeFavouriteChapter(item);
    this.cdr.markForCheck();
  }

  private compareVerse(item: FavouriteChapter): void {
    this.compareVerse$.emit(item);
  }
}
