import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';

import { CompareVerseDialogComponent } from './compare-verse-dialog.component';
import { CompareVerseBottomSheetModule } from './bottom-sheet/compare-verse-bottom-sheet.module';
import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [CompareVerseDialogComponent],
  imports: [
    CommonModule,
    CompareVerseBottomSheetModule,
    DragDropModule,
    IconButtonComponent,
    MatDialogModule,
    MatIconModule,
    MediaDirectivesModule,
    SharedPipesModule,
  ],
  exports: [CompareVerseDialogComponent],
})
export class CompareVerseDialogModule {}
