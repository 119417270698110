import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

export enum BibleSideExpandState {
  EXPAND,
  COLLAPSE,
}

@Component({
  selector: 'app-bible-collapse-button',
  templateUrl: './bible-collapse-button.component.html',
  styleUrls: ['./bible-collapse-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleCollapseButtonComponent {
  @Input({ required: true }) @HostBinding('class.reverse') protected reverse = false;
  @Input({ required: true }) @HostBinding('class.hidden') protected hidden = false;
  @Input({ required: true }) protected readonly blockTimeout = 800;

  protected readonly BibleSideExpandState = BibleSideExpandState;

  protected tooltip = 'zwiń panel';
  protected ariaLabel = `zwiń ${this.reverse ? 'lewy' : 'prawy'} panel`;
  protected state = BibleSideExpandState.EXPAND;

  @Output() private readonly expandChange$ = new EventEmitter<BibleSideExpandState>();

  private blockExpandChange: boolean;

  protected arrowClick(): void {
    if (!this.blockExpandChange && !this.hidden) {
      this.state = this.state === BibleSideExpandState.EXPAND ? BibleSideExpandState.COLLAPSE: BibleSideExpandState.EXPAND;
      this.expandChange$.emit((this.state))
      setTimeout(() => this.tooltip = this.state ? 'rozwiń panel' : 'zwiń panel', 10);
      setTimeout(() => this.ariaLabel = this.state ?
        `rozwiń ${this.reverse ? 'lewy' : 'prawy'} panel` : `zwiń ${this.reverse ? 'lewy' : 'prawy'} panel`, 10);
      this.blockExpandChange = true;
      setTimeout(() => this.blockExpandChange = false, this.blockTimeout);
    }
  }
}
