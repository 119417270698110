<form [formGroup]="form" class="form">
  @if (!forceCollapse && !blockCollapse) {
    <app-bible-collapse-button
      (expandChange$)="expandChange($event)">
    </app-bible-collapse-button>
  }

  <mat-form-field appearance="fill" class="field-bible"
    [matTooltip]="collapse ? bibleTooltip : null" matTooltipPosition="left">
    <mat-label>Biblia</mat-label>
    <mat-select formControlName="bible" tabIndex="-1" (selectionChange)="bibleSelectionChange($event.value)">
      @for (group of bibleGroup; track group.name) {
        <mat-optgroup [label]="group.name">
          @for (item of group.options; track item.abbreviation) {
            <mat-option [value]="item.abbreviation"
              [matTooltip]="collapse ? item.name : null" matTooltipPosition="left">
              {{ collapse ? (item.abbreviation | uppercase) : item.name }}
            </mat-option>
          }
        </mat-optgroup>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="field-book"
    [matTooltip]="collapse ? bookTooltip : null" matTooltipPosition="left">
    <mat-label>Księga</mat-label>
    <mat-select formControlName="book" tabIndex="-1" (selectionChange)="bookSelectionChange($event.value)">
      @for (group of bookGroup; track group.name) {
        <mat-optgroup [label]="group.name">
          @for (item of group.options; track item.abbreviation) {
            <mat-option [value]="item.abbreviation"
              [matTooltip]="collapse ? item.name : null" matTooltipPosition="left">
              {{ collapse ? (item.abbreviation | abbreviation) + '.' : item.name }}
            </mat-option>
          }
        </mat-optgroup>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="field-chapter">
    <mat-label>Rozdział</mat-label>
    <mat-select formControlName="chapter" tabIndex="-1" (selectionChange)="chapterSelectionChange$.emit(form)">
      @for (chapter of chapters; track chapter) {
        <mat-option [value]="chapter">{{ chapter }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="bible-compare-container">
    <mat-form-field appearance="fill" class="field-bible-compare"
      [matTooltip]="collapse ? compareBibleTooltip : null" matTooltipPosition="left">
      <mat-label>Porównaj z</mat-label>
      <mat-select formControlName="bibleCompare" multiple tabIndex="-1" #select (selectionChange)="bibleCompareSelectionChange($event.value, select)">
        @if (bibleGroup) {
          @if (bibleGroup[0]?.options.length > 0) {
            <mat-optgroup>
              {{ bibleGroup[0].name }}
              @for (item of bibleGroup[0].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation" class="box"
                        [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [matTooltip]="collapse && !option.disabled ? item.name : null" matTooltipPosition="left" #option>
                  <span class="name">{{ collapse ? (item.abbreviation | uppercase) : item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[1]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[1].name">
              @for (item of bibleGroup[1].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation" class="box"
                        [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [matTooltip]="collapse && !option.disabled ? item.name : null" matTooltipPosition="left" #option>
                  <span class="name">{{ collapse ? (item.abbreviation | uppercase) : item.name }}</span>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[2]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[2].name">
              @for (item of bibleGroup[2].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                        [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [matTooltip]="collapse && !option.disabled ? item.name : null" matTooltipPosition="left" #option>
                  {{ collapse ? (item.abbreviation | uppercase) : item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (bibleGroup[3]?.options.length > 0) {
            <mat-optgroup [label]="bibleGroup[3].name">
              @for (item of bibleGroup[3].options; track item.abbreviation) {
                <mat-option [value]="item.abbreviation"
                        [disabled]="item.abbreviation === form.get('bible').value
                              || (bibleCompare | overLimit : item.abbreviation : maxCompareColumns)
                              || !(form.get('book').value | isBibleAvailable : item.abbreviation)"
                  [matTooltip]="collapse && !option.disabled ? item.name : null" matTooltipPosition="left" #option>
                  {{ collapse ? (item.abbreviation | uppercase) : item.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>
