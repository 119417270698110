import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';

import { CompareVerseBottomSheetComponent } from './compare-verse-bottom-sheet.component';

@NgModule({
  declarations: [CompareVerseBottomSheetComponent],
    imports: [CommonModule, MatIconModule],
  exports: [CompareVerseBottomSheetComponent],
})
export class CompareVerseBottomSheetModule {}
