import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener, inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MatSelect } from '@angular/material/select';

import { BibleFilterComponent } from '../bible-filter/bible-filter.component';
import { BibleHelper } from '../../../../../services/helpers/bible.helper';
import { BibleSideExpandState } from './bible-collapse-button/bible-collapse-button.component';
import { BibliaInfoChapterModel } from '../../../../../services/biblia-info/biblia-info.model';
import { BibliaInfoCode } from '../../../../../services/biblia-info/biblia-info-code';

@Component({
  selector: 'app-bible-right-panel',
  templateUrl: 'bible-right-panel.component.html',
  styleUrls: ['./bible-right-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleRightPanelComponent extends BibleFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input({ required: true }) isFavouriteChapter: boolean;
  @Input({ required: true }) forceCollapse: boolean;
  @Input({ required: true }) hidePrevButton = false;
  @Input({ required: true }) hideNextButton = false;
  @Input({ required: true }) stopAnimationOnHideButtonChange = false;
  @Input({ required: true }) compareChapterItems: Map<BibliaInfoCode, BibliaInfoChapterModel>;
  @Input({ required: true }) @HostBinding('class.over-1700') over1700px: boolean;
  @HostBinding('class.visible') visible = false;
  @HostBinding('class.collapse') collapse = false;

  private cdr = inject(ChangeDetectorRef);

  protected bibleTooltip: string;
  protected bookTooltip: string;
  protected compareBibleTooltip: string;

  protected blockCollapse: boolean;

  private static showFilterOverHeightPx = 180;

  private blockCollapseValue: boolean;
  private blockTimeoutHandle: number;

  private scrollTopElement: HTMLElement;

  ngOnInit(): void {
    this.scrollTopElement = document.body.querySelector('app-scroll-top-button') as HTMLElement;
    this.scrollTopElement?.classList.add('bible-view');
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.forceCollapse) {
      this.blockCollapseValue = changes.forceCollapse.currentValue;

      if (!this.blockCollapse) {
        this.collapse = changes.forceCollapse.currentValue;
      } else {
        if (!this.collapse) {
          setTimeout(() => this.collapse = true, 800);
        }
      }
      this.blockCollapse = true;
      clearTimeout(this.blockTimeoutHandle);
      this.blockTimeoutHandle = setTimeout(() => {
        this.collapse = this.blockCollapseValue;
        this.blockCollapse = false;
        this.cdr.markForCheck();
      }, 800) as unknown as number;
    }
    if (!this.forceCollapse) {
      this.visible = window.scrollY > BibleRightPanelComponent.showFilterOverHeightPx;
    }
    if(changes.bibleGroup) {
      this.bibleTooltip = BibleHelper.getBibleName(this.form.get('bible').value, this.bibleGroup);
    }
    if(changes.compareChapterItems) {
      this.compareBibleTooltip = BibleHelper.getBibleName(this.form.get('bibleCompare').value[0], this.bibleGroup);
    }
    if(changes.bookGroup) {
      this.bookTooltip = BibleHelper.getBookName(this.form.get('book').value, this.bookGroup);
    }
  }

  ngOnDestroy(): void {
    this.scrollTopElement?.classList.remove('bible-view');
  }

  @HostListener('window:scroll', ['$event'])
  trackScrollPosition(): void {
    this.visible = window.scrollY > BibleRightPanelComponent.showFilterOverHeightPx;
  }

  protected expandChange($event: BibleSideExpandState): void {
    this.collapse = $event === BibleSideExpandState.COLLAPSE;
  }

  protected bibleSelectionChange(value: string): void {
    this.bibleTooltip = BibleHelper.getBibleName(value, this.bibleGroup);
    this.bibleSelectionChange$.emit(this.form);
  }

  protected bibleCompareSelectionChange(value: string, select: MatSelect): void {
    if (value) {
      this.compareBibleTooltip = BibleHelper.getBibleName(value, this.bibleGroup);
      this.bibleCompareSelectionChange$.emit(this.form);
      select.close();
    }
  }

  protected bookSelectionChange(value: string): void {
    this.bookTooltip = BibleHelper.getBookName(value, this.bookGroup);
    this.bibleSelectionChange$.emit(this.form);
  }
}
