import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgModule } from '@angular/core';

import { BibleFavouritesMobileDialogComponent } from './bible-favourites-mobile-dialog.component';
import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { LeftPanelService } from '../../../../services/left-panel/left-panel.service';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [BibleFavouritesMobileDialogComponent],
  imports: [
    CommonModule,
    DragDropModule,
    IconButtonComponent,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    SharedPipesModule,
  ],
  exports: [BibleFavouritesMobileDialogComponent],
  providers: [LeftPanelService]
})
export class BibleFavouritesMobileDialogModule {}
