import { BibleGroup } from '../../pages/common/bible/bible.model';

export class BibleHelper {
  static getBibleName(abbreviation: string, bibleGroup: BibleGroup[]): string {
    return bibleGroup
      ?.map(group => group.options)
      .reduce((prev, curr) => [...prev, ...curr])
      .find(option => option.abbreviation === abbreviation)?.name;
  }

  static getBookName(abbreviation: string, bookGroup: BibleGroup[]): string {
    return bookGroup
      ?.map(group => group.options)
      .reduce((prev, curr) => [...prev, ...curr])
      .find(option => option.abbreviation === abbreviation)?.name;
  }
}
