<app-icon-button label="wersety w nowej linii" matTooltip="wersety w nowej linii" icon="toc" rotateIcon="true"
                 class="verses-in-new-line alt-hover" [disabled]="hideVerseInNewLine" [active]="versesInNewLine"
                 (click$)="versesInNewLine = !versesInNewLine"
></app-icon-button>

<app-icon-button label="wyszukiwarka" matTooltip="wyszukiwarka" icon="search" link="../wyszukaj"
></app-icon-button>

<app-icon-button label="wybór przekładów biblijnych" matTooltip="wybór przekładów" icon="settings"
                 (click$)="openSelectTranslationsDialog$.emit()"
></app-icon-button>

<app-icon-button label="kopiuj wersety" matTooltip="kopiuj wersety" icon="content_copy" (click$)="copyVerses$.emit()"
></app-icon-button>

<app-icon-button label="zmiana rozmiary czcionki" matTooltip="zmiana rozmiary czcionki" icon="font-increase"
                 (click$)="changeFontSize()"
></app-icon-button>
