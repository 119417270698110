import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';

import { BibleBookInfoDialogComponent } from './bible-book-info-dialog.component';
import { IconButtonComponent } from '../../../../components/icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

@NgModule({
  declarations: [BibleBookInfoDialogComponent],
  imports: [
    ...BibleBookInfoDialogModule.angular,
    ...BibleBookInfoDialogModule.app,
    ...BibleBookInfoDialogModule.material,
  ],
  exports: [BibleBookInfoDialogComponent],
})
export class BibleBookInfoDialogModule {
  static angular = [
    CommonModule,
    FormsModule,
  ];

  static app = [
    IconButtonComponent,
    MediaDirectivesModule,
    SharedPipesModule,
  ];

  static material = [
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
  ];
}
